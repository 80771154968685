define("ember-img-lazy/components/img-lazy", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Promise = Ember.RSVP.Promise;

  var emptySvg = function emptySvg(width, height) {
    return "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"".concat(width | 0, "\" height=\"").concat(height | 0, "\"></svg>");
  }; // Build an svg of the given size to prelayout the correct space


  var placeholder = function placeholder(width, height) {
    return width && height ? "data:image/svg+xml;charset=utf8,".concat(encodeURIComponent(emptySvg(width, height))) : 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEAAAAALAAAAAABAAEAAAI=';
  };

  var isFastBoot = typeof FastBoot !== 'undefined';

  var _default = Ember.Component.extend({
    tagName: 'img',
    config: _emberGetConfig.default,
    observer: Ember.inject.service('img-lazy-observer'),
    classNames: ['img-lazy'],
    classNameBindings: ['_loaded:img-lazy--loaded', '_loading:img-lazy--loading', '_error:img-lazy--error'],
    lazyFastBoot: Ember.computed.oneWay('config.ember-img-lazy.lazyFastBoot'),
    immediately: Ember.computed.oneWay('config.ember-img-lazy.setSrcImmediately'),
    _src: Ember.computed('width', 'height', 'src', function () {
      if (isFastBoot && !Ember.get(this, 'lazyFastBoot') || !isFastBoot && !Ember.get(this, 'observer.hasIntersectionObserver')) {
        return Ember.get(this, 'src');
      }

      return placeholder(Ember.get(this, 'width'), Ember.get(this, 'height'));
    }),
    _error: null,
    _loaded: Ember.computed.not('observer.hasIntersectionObserver'),
    _loading: Ember.computed.oneWay('observer.hasIntersectionObserver'),
    src: null,
    title: null,
    alt: null,
    width: null,
    height: null,
    attributeBindings: ['_src:src', 'title', 'alt', 'width', 'height'],
    didInsertElement: function didInsertElement() {
      Ember.get(this, 'observer').observe(this);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      if (this._src !== this.src) {
        this.loadImage();
      }
    },
    willDestroyElement: function willDestroyElement() {
      Ember.get(this, 'observer').unobserve(this);
    },
    loadImage: function loadImage() {
      var _this = this;

      var src = Ember.get(this, 'src');

      if (src === null) {
        return;
      }

      this._setIsLoading();

      if (Ember.get(this, 'immediately')) {
        Ember.set(this, '_src', src);
        Ember.run.scheduleOnce('afterRender', this, function () {
          if (isLoaded(_this.element)) {
            _this._setIsLoaded();

            return;
          }

          _this.element.onload = Ember.run.bind(_this, _this._setIsLoaded);
          _this.element.onerror = Ember.run.bind(_this, _this._setError);
        });
        return;
      }

      fetchImage(src).then(function () {
        if (_this.isDestroyed === true) {
          return;
        }

        Ember.set(_this, '_src', src);

        _this._setIsLoaded();
      }).catch(function (err) {
        _this._setError(err);
      });
    },
    _setIsLoaded: function _setIsLoaded() {
      if (this.isDestroyed === true) {
        return;
      }

      Ember.set(this, '_loaded', true);
      Ember.set(this, '_loading', false);
      Ember.set(this, '_error', null);
    },
    _setIsLoading: function _setIsLoading() {
      if (this.isDestroyed === true) {
        return;
      }

      Ember.set(this, '_loaded', false);
      Ember.set(this, '_loading', true);
      Ember.set(this, '_error', null);
    },
    _setError: function _setError(err) {
      if (this.isDestroyed === true) {
        return;
      }

      Ember.set(this, '_loaded', false);
      Ember.set(this, '_loading', false);
      Ember.set(this, '_error', err || true);
    }
  });

  _exports.default = _default;

  function isLoaded(img) {
    return img.completed === true || img.readyState === 4;
  }

  function fetchImage(url) {
    return new Promise(function (resolve, reject) {
      return setTimeout(function () {
        return Ember.run(function () {
          var img = new Image();
          img.src = url;

          if (isLoaded(img)) {
            resolve(url);
          } else {
            img.onload = resolve.bind(null, url);
            img.onerror = reject;
          }
        });
      });
    });
  }
});