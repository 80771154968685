define("ember-img-lazy/services/img-lazy-observer", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_OBSERVER_CONFIG = {
    rootMargin: '50px 0px',
    threshold: 0.01
  };

  var _default = Ember.Service.extend({
    observer: null,
    config: _emberGetConfig.default,
    _config: null,
    init: function init() {
      this._super();

      this.hasIntersectionObserver = typeof FastBoot === 'undefined' && 'IntersectionObserver' in window;

      if (this.hasIntersectionObserver) {
        this.components = [];
        this._config = Ember.assign({}, DEFAULT_OBSERVER_CONFIG, Ember.get(this, 'config.ember-img-lazy.observerConfig') || {});
        this.onIntersection = Ember.run.bind(this, this.onIntersection);
      }
    },
    createObserver: function createObserver() {
      if (this.observer) {
        throw new Error('There is already an intersection observer present');
      }

      this.observer = new IntersectionObserver(this.onIntersection, this._config);
    },
    stopObserver: function stopObserver() {
      this.observer.disconnect();
      this.observer = null;
    },
    observe: function observe(component) {
      if (this.hasIntersectionObserver === false) {
        return;
      }

      if (this.observer === null) {
        this.createObserver();
      }

      this.observer.observe(component.element);
      this.components.push(component);
    },
    unobserve: function unobserve(component) {
      if (this.hasIntersectionObserver === false || this.observer === null) {
        return;
      }

      try {
        this.observer.unobserve(component.element);
      } catch (e) {
        console.error(e); // eslint-disable-line
      }

      this.components = this.components.filter(function (c) {
        return c !== component;
      });

      if (this.components.length === 0) {
        this.stopObserver();
      }
    },
    onIntersection: function onIntersection(entries) {
      for (var i = 0; i < entries.length; i++) {
        if (entries[i].intersectionRatio > 0) {
          var component = this.getComponent(entries[i].target);

          if (component !== undefined) {
            this.unobserve(component);
            component.loadImage();
          }
        }
      }
    },
    getComponent: function getComponent(img) {
      return this.components.find(function (c) {
        return c.element === img;
      });
    }
  });

  _exports.default = _default;
});